import React from "react"
// import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const LecureSeriesVideo = () => (
  <Layout bgcolor="#fec450">
    <SEO title="2020 Lecture" bgcolor="#ffcc00" />
  <div>
    <h1>2020 Lecture</h1>
    <p style={{display: 'none'}}>Watch the video on November 7th at 7:00 pm.</p>
    
    <p>The Annual McCarthey Family Foundation Lecture Series: In Praise of Independent Journalism took place Saturday November 7th. Not only was this Lecture of special note as the fifteenth in the Series but it is also unique in that it was virtual, given the very real risks of the pandemic.</p>
    <p>The Lecture recognized the winners of the National Student Essay Competition and the Utah Essay Competition and those who have supported the Foundation in engaging student voices in dialogue about the importance of freedom of the press. We extend special thanks to The Boston Globe, the Boston Globe Foundation, Westminster College, the National Essay Competition Honorary Advisory Committee and Jury, and the Jury for the Utah Competition.</p>
    <p>The presidential election has now taken place. The Foundation was delighted to welcome the perfect analyst to put that momentous event into penetrating perspective: guest lecturer, Jane Mayer, investigative journalist and staff writer for The New Yorker. Following her remarks, Rowland Hall student engaged with Jane in a real time Q&amp;A.</p>
    <p>Thank you for making the Lecture Series a meaningful and dynamic public event. The McCarthey Family invites you to watch the recording on YouTube by visiting <a href="https://www.youtube.com/watch?v=Yx5slXeury0&feature=youtu.be" target="_blank">this page.</a></p>

  </div>
  </Layout>
)

export default LecureSeriesVideo